import { requests } from './utils';


export const OrderAPI = {
	get: () => requests.get(`order/`),
	findById: (id) => requests.get(`order/${id}`),
	create: (order) => requests.post('order/create', order),
	update: (order) => requests.post('order/update', order),
	setDeliverynoteNum: (infos) => requests.post('order/set/deliverynote/num', infos),
	setInvoiceNum: (infos) => requests.post('order/set/invoice/num', infos)
};
