import app from '../config';
// ----------------------------------------------------------------------

const account = {
  displayName: "Admin",
  email: `admin@${app.name}.com`,
  role: "Admin",
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
