import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';


export function Device({ device }) {

  return <Card sx={{
    mt: 2,
    py: 4
  }} /*{...props}*/>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {device.manufacturer}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {`${device.modelName}`}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {`${device.osVersion}`}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {`Est un appareil : ${device.isDevice}`}
        </Typography>
      </Box>
    </CardContent>
  </Card>
};
