// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('typcn:chart-pie'),
  },
  {
    title: 'Utilisateurs',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Catalogues',
    path: '/dashboard/catalogs',
    icon: getIcon('fluent:clipboard-task-list-rtl-24-filled'),
  },
  {
    title: 'Arrivages',
    path: '/dashboard/arrivals',
    icon: getIcon('fa6-solid:plane-arrival'),
  },
  {
    title: 'Avis',
    path: '/dashboard/reviews',
    icon: getIcon('material-symbols:reviews-rounded'),
  },
  {
    title: 'Commandes',
    path: '/dashboard/orders',
    icon: getIcon('icon-park-solid:order'),
  },
  {
    title: 'Produits',
    path: '/dashboard/showcase',
    icon: getIcon('eva:shopping-cart-fill'),
  },
  {
    title: 'Fournisseurs',
    path: '/dashboard/suppliers',
    icon: getIcon('carbon:scis-transparent-supply'),
  },
  {
    title: 'SMS Pro +',
    path: '/dashboard/sms',
    icon: getIcon('eva:message-square-outline'),
  },
  {
    title: 'Configuration',
    path: '/dashboard/configuration',
    icon: getIcon('eva:settings-fill'),
  },
];

export default navConfig;
