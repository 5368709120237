import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    CardActions,
    FormControl,
    FormLabel,
    Typography,
    Stack,
    Avatar,
    ButtonBase,
    IconButton,
} from '@mui/material';
import Iconify from '../Iconify';
import { BrandAPI } from 'src/api/brand';
import React from 'react';
import { getRandomValues } from 'crypto';
import app from 'src/config';



/// <devdoc>
///    <para>  Supplier interface. </para>
/// </devdoc>


export interface ISupplier {
    name: string;
}


/// <devdoc>
///    <para>  Create new supplier modal. </para>
/// </devdoc>


export const SupplierAdd = ({ create, update, form }) => {

    const [logo, setLogo] = useState(null)


    //const { name, createdAt, brand } = form.supplier
    const AddSupplierSchema = Yup.object().shape({
        name: Yup.string().max(200).min(3).required('le Nom est requis')
    });


    const formik = useFormik({
        initialValues: form.supplier ? form.supplier : {
            name: null,
            logo: ""
        } as ISupplier,

        validationSchema: AddSupplierSchema,
        onSubmit: (values, actions) => {
            onSubmit(values);
        },
    });



    /// <summary>
    /// on component mount
    /// </summary>


    React.useEffect(() => {

        // update formik initial values on edit
        if (form.supplier && formik.initialValues != form.supplier) {
            formik.setValues({ ...form.supplier });
        }

        return () => { }
    }, [form])



    /// <summary>
    /// Handle Excel file upload
    /// </summary>


    const handleLogoUpload = (e) => {
        setLogo(e.target.files[0])
    }

    /// <summary>
    /// is editing form
    /// </summary>


    const isEditing = (): boolean => {
        return form.supplier != null
    }



    /// <summary>
    /// Submit supplier
    /// </summary>


    const onSubmit = (values) => {
        const supplier = new FormData();

        // set name
        supplier.append('name', values.name);
        // set file
        if (logo) supplier.append('attachment', logo);

        if (isEditing()) {
            supplier.append('_id', form.supplier._id);
            update(supplier);
            return;
        }

        create(supplier);
    }



    const { errors, touched, values, setFieldValue, handleChange, handleSubmit, getFieldProps }: any = formik;


    return (
        <form
            autoComplete="off"
            noValidate
        //{...props}
        >
            <Card>
                <CardHeader
                    subheader="Informations"
                    title={`${isEditing() ? "Modifier" : "Ajouter"} Fournisseur`}
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Stack mb={1} direction="row" spacing={2}>

                                <Button
                                    sx={{ borderRadius: 50 }}
                                    variant="text"
                                    component="label"
                                >
                                    <input
                                        onChange={handleLogoUpload}
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        id="raised-button-file"
                                        //multiple
                                        name="supplier[file]"
                                    />
                                    <Avatar
                                        src={logo ? URL.createObjectURL(logo) : (app.route + values.logo.filename || "")}
                                        sx={{ width: 56, height: 56 }}
                                    />

                                </Button>

                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        sx={{ height: 56 }}
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        placeholder="Nom"
                                        aria-describedby="outlined-weight-helper-text"
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </FormControl>

                                <Button
                                    sx={{ height: 56 }}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {isEditing() ? "Modifier" : "Créer"}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form >
    );
};
