export default [
    { value: "ADRAR", label: "ADRAR" },
    { value: "CHLEF", label: "CHLEF" },
    { value: "LAGHOUAT", label: "LAGHOUAT" },
    { value: "OUMELBOUAGHI", label: "OUM EL BOUAGHI" },
    { value: "BATNA", label: "BATNA" },
    { value: "BÉJAÏA", label: "BÉJAÏA" },
    { value: "BISKRA", label: "BISKRA" },
    { value: "BECHAR", label: "BECHAR" },
    { value: "BLIDA", label: "BLIDA" },
    { value: "BOUIRA", label: "BOUIRA" },
    { value: "TAMANRASSET", label: "TAMANRASSET" },
    { value: "TBESSA", label: "TBESSA" },
    { value: "TLEMCEN", label: "TLEMCEN" },
    { value: "TIARET", label: "TIARET" },
    { value: "TIZIOUZOU", label: "TIZI OUZOU" },
    { value: "ALGER", label: "ALGER" },
    { value: "DJELFA", label: "DJELFA" },
    { value: "JIJEL", label: "JIJEL" },
    { value: "SE9TIF", label: "SE9TIF" },
    { value: "SAEFDA", label: "SAEFDA" },
    { value: "SKIKDA", label: "SKIKDA" },
    { value: "SIDIBELABBES", label: "SIDI BEL ABBES" },
    { value: "ANNABA", label: "ANNABA" },
    { value: "GUELMA", label: "GUELMA" },
    { value: "CONSTANTINE", label: "CONSTANTINE" },
    { value: "MEDEA", label: "MEDEA" },
    { value: "MOSTAGANEM", label: "MOSTAGANEM" },
    { value: "MSILA", label: "MSILA" },
    { value: "MASCARA", label: "MASCARA" },
    { value: "OUARGLA", label: "OUARGLA" },
    { value: "ORAN", label: "ORAN" },
    { value: "ELBAYADH", label: "EL BAYADH" },
    { value: "ILLIZI", label: "ILLIZI" },
    { value: "BORDJBOUARRERIDJ", label: "BORDJ BOU ARRERIDJ" },
    { value: "BOUMERDES", label: "BOUMERDES" },
    { value: "ELTARF", label: "EL TARF" },
    { value: "TINDOUF", label: "TINDOUF" },
    { value: "TISSEMSILT", label: "TISSEMSILT" },
    { value: "ELOUED", label: "EL OUED" },
    { value: "KHENCHELA", label: "KHENCHELA" },
    { value: "SOUKAHRAS", label: "SOUK AHRAS" },
    { value: "TIPAZA", label: "TIPAZA" },
    { value: "MILA", label: "MILA" },
    { value: "AINDEFLA", label: "AIN DEFLA" },
    { value: "NAAMA", label: "NAAMA" },
    { value: "AINTEMOUCHENT", label: "AIN TEMOUCHENT" },
    { value: "GHARDAEFA", label: "GHARDAEFA" },
    { value: "RELIZANE", label: "RELIZANE" },
    { value: "ELMGHAIR", label: "EL MGHAIR" },
    { value: "ELMENIA", label: "EL MENIA" },
    { value: "OULEDDJELLAL", label: "OULED DJELLAL" },
    { value: "BORDJBAJIMOKHTAR", label: "BORDJ BAJI MOKHTAR" },
    { value: "BÉNIABBÈS", label: "BÉNI ABBÈS" },
    { value: "TIMIMOUN", label: "TIMIMOUN" },
    { value: "TOUGGOURT", label: "TOUGGOURT" },
    { value: "DJANET", label: "DJANET" },
    { value: "INSALAH", label: "IN SALAH" },
    { value: "INGUEZZAM", label: "IN GUEZZAM" }
  ];
  