import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { AccountProfile } from '../components/account/account-profile';
import { Device } from '../components/account/device';
import { AccountProfileDetails } from '../components/account/account-profile-details';
import { useState, useEffect } from 'react';
import Settings from './Settings';


export default function Account() {
  const location = useLocation();
  const [user, setUser] = useState<any>(location.state)
  const navigate = useNavigate();


  useEffect(() => {
    if (!user) navigate('/404');
    return () => { }
  }, [user])


  return user && <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Typography
          sx={{ mb: 3 }}
          variant="h4"
        >
          Profile
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <AccountProfile user={user} />
            {user.device && <Device device={user.device} />}
          </Grid>

          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <AccountProfileDetails user={user} />
          </Grid>
          <Settings user={user} />
        </Grid>
      </Container>
    </Box>
  </>
};
