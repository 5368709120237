import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, useFormikContext } from 'formik';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    CardActions,
    FormControl,
    FormLabel,
    Typography,
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../Iconify';
import { BrandAPI } from 'src/api/brand';
import React from 'react';




/// <devdoc>
///    <para>  Catalog interface. </para>
/// </devdoc>

export interface ICatalog {
    name: string;
    createdAt: Date;
    brand: string;
}


/// <devdoc>
///    <para>  Create new catalog modal. </para>
/// </devdoc>


export const CatalogAdd = ({ create, update, form }) => {

    const [brands, setBrands] = useState(null);
    const [excel, setExcel] = useState(null)


    //const { name, createdAt, brand } = form.catalog
    const AddCatalogSchema = Yup.object().shape({
        name: Yup.string().max(200).min(3).required('le Nom est requis'),
        createdAt: Yup.date().required('la date de création est requis'),
        brand: Yup.string().required('la marque est requis'),
    });


    const formik = useFormik({
        initialValues: form.catalog ? form.catalog : {
            name: null,
            createdAt: new Date(),
            brand: ''
        } as ICatalog,

        validationSchema: AddCatalogSchema,
        onSubmit: (values, actions) => {
            onSubmit(values);
        },
    });



    /// <summary>
    /// on component mount
    /// </summary>


    React.useEffect(() => {

        // update formik initial values on edit
        if (form.catalog && formik.initialValues != form.catalog) {
            formik.setValues({ ...form.catalog, brand: form.catalog.brand.name });
        }
        // get brands
        if (!brands) getBrands()
        return () => { }
    }, [brands, form])



    /// <summary>
    /// Handle Excel file upload
    /// </summary>


    const handleExcelUpload = (e) => {
        setExcel(e.target.files[0])
    }

    /// <summary>
    /// is editing form
    /// </summary>


    const isEditing = (): boolean => {
        return form.catalog != null
    }


    /// <summary>
    /// get brands
    /// </summary>


    const getBrands = (): void => {
        BrandAPI.get()
            .then(res => {
                setBrands(res.data.map(b => {
                    return { value: b.name, label: b.name.toUpperCase(), ...b }
                }))
            })
            .catch((e) => console.log(e));
    }


    /// <summary>
    /// Submit catalog
    /// </summary>


    const onSubmit = (values) => {
        const catalog = new FormData();

        // set date
        catalog.append('createdAt', values.createdAt);

        // set name
        catalog.append('name', values.name);

        // set brand
        const brand = brands.find(b => b.value == values.brand)
        catalog.append('brand', brand._id);

        if (isEditing()) {
            update({
                _id: form.catalog._id,
                name: values.name,
                brand: brand._id,
                createdAt: values.createdAt
            });
            return;
        }

        // set file
        if (excel) catalog.append('attachment', excel);
        create(catalog);
    }



    const { errors, touched, values, setFieldValue, handleChange, handleSubmit, getFieldProps }: any = formik;


    return (
        <form
            autoComplete="off"
            noValidate
        //{...props}
        >
            <Card>
                <CardHeader
                    subheader="Informations"
                    title={`${isEditing() ? "Modifier" : "Ajouter"} Catalogue`}
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    placeholder="Nom"
                                    aria-describedby="outlined-weight-helper-text"
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Date de création "
                                    minDate={new Date('2010-01-01')}
                                    maxDate={new Date()}
                                    value={values.createdAt}
                                    onChange={(value) => setFieldValue("createdAt", value, true)}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            error={Boolean(touched.createdAt && errors.createdAt)}
                                            helperText={touched.createdAt && errors.createdAt}
                                            fullWidth
                                        />)}
                                />
                            </LocalizationProvider>
                            <Typography fontSize={12} color="green">{errors.createdAt}</Typography>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                id="brand"
                                fullWidth
                                label="Marque"
                                name="brand"
                                {...getFieldProps('brand')}
                                defaultValue=""
                                onChange={handleChange('brand')}
                                error={Boolean(touched.brand && errors.brand)}
                                helperText={touched.createdAt && errors.createdAt}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={values.brand}
                                variant="outlined"
                            >
                                <option
                                    key="brand"
                                    value=""
                                >
                                </option>
                                {brands && brands.map((o) => (
                                    <option
                                        key={o.value}
                                        value={o.value}
                                    >
                                        {o.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardHeader
                    subheader="Le fichier doit avoir un des formats microsoft office (doc, xlsx, ppt...) ou pdf."
                />
                <Divider />
                {!isEditing() &&
                    <>
                        <Divider />
                        <CardActions>
                            <Button
                                fullWidth
                                variant="text"
                                component="label"
                            >
                                <Iconify color="#E83E00" width={40} height={40} icon="mdi:microsoft-office" />
                                {excel
                                    ? <Iconify color='#949497' width={40} height={40} icon="ci:check" />
                                    : <Iconify color='#949497' width={40} height={40} icon="line-md:upload-loop" />
                                }
                                <input
                                    onChange={handleExcelUpload}
                                    type="file"
                                    hidden
                                    accept=".xlsx, .xls, .doc, .docx,.ppt, .pptx, .csv, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                                    id="raised-button-file"
                                    //multiple
                                    name="catalog[file]"
                                />
                            </Button>
                        </CardActions>
                    </>
                }
                <Grid
                    p={3}
                    md={12}
                    xs={12}
                >
                    <div style={{ marginBottom: 15 }}>
                        {excel && <FormLabel id="demo-row-radio-buttons-group-label">{excel.name} séléctionnée.</FormLabel>}
                    </div>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {isEditing() ? "Modifier" : "Ajouter"} Catalogue
                    </Button>
                </Box>
            </Card>
        </form>
    );
};
