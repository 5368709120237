import { filter } from 'lodash';
// material
import {
    Card,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination
} from '@mui/material';

// components
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { DateRangePickerDay } from '@mui/lab';



export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}



export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}




export function applySortFilter(array, comparator, query, searchBy = "name") {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (obj) => {
            return obj[searchBy].toLowerCase().indexOf(query.toLowerCase()) !== -1
        });
    }
    return stabilizedThis.map((el) => el[0]);
}




export default function MuiTable({
    data, columns, TableBody,  // table components
    page, setPage, // usePage
    order, setOrder, // useOrder
    orderBy, setOrderBy, // useOrderBy
    selected, setSelected, // useSelected
    filterName, setFilterName, // useFilterName
    rowsPerPage, setRowsPerPage, // useRowsPerPage
    searchBy = "name", showToolbar = true
}) {


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const filteredData = applySortFilter(data, getComparator(order, orderBy), filterName, searchBy);
    const isUserNotFound = filteredData.length === 0;


    return (
        <Card>
            {showToolbar && <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={columns}
                            rowCount={DateRangePickerDay.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody />

                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card >
    )
}