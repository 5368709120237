import { useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// context
import { IAppContext, Context } from "./app/context";
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { SnackbarProvider } from 'notistack';
import { ConfigAPI } from './api/config';
import { createTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------


export default function App() {

  const [context, setContext] = useState<IAppContext | any>(null);

  useEffect(() => {
    if (!context) getDefaultValues()
    return () => { }
  }, [context])


  /// <summary>
  /// get default values.
  /// </summary>


  const getDefaultValues = () => {
    ConfigAPI.get()
      .then(res => setContext({ defaultValues: res.data }))
      .catch((e) => console.log(e));
  };



  return (
    <ThemeProvider>
      <Context.Provider value={[context, setContext]}>
        <SnackbarProvider maxSnack={3}>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </SnackbarProvider>
      </Context.Provider>
    </ThemeProvider>
  );
}


