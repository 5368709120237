const catalogs = [
  {
    _id: '625b1bd1a2013b04f13a211h',
    name: "Poussettes",
    brand: "Tommy Hilfiger",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211j',
    name: "Poussettes",
    brand: "Addidas",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211f',
    name: "Poussettes",
    brand: "Nike",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211b',
    name: "Poussettes",
    brand: "Gucci",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211e',
    name: "Poussettes",
    brand: "Armani",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211d',
    name: "Poussettes",
    brand: "Hugo Boss",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: '625b1bd1a2013b04f13a211c',
    name: "Poussettes",
    brand: "Zara",
    attachment: "PDF",
    createdAt: new Date(),
    updatedAt: new Date()
  },

]

export default catalogs