import { requests } from './utils';

export const ProductAPI = {
	getById: (id) => requests.get(`product/id/${id}`),
	getByCatalog: (id) => requests.get(`product/catalog/${id}`),
	highlight: (state) => requests.post(`product/highlight`, state),
	get: () => requests.get('product/'),
	findByRefs: (refs) => requests.post('product/refs', refs),
	forceUpdate: (refs) => requests.post('product/fix/catalogs', refs),
	updateMulti: (products) => requests.post('product/update/multi', products),
	create: (form) => requests.post('product/create', form),
	update: (form) => requests.post('product/update', form),
	delete: (id) => requests.delete(`product/${id}`),
};