const app = {
    name: "Andalousia",
    pole: "NotEasy",
    domain: "www.andaloussia-shop.com",
    route: "https://api.andaloussia-shop.com/static/",
    // route: "http://localhost:3000/static/",
    baseURL: `https://api.andaloussia-shop.com/v1/`,
    // baseURL: `http://localhost:3000/v1/`,
    apiKey: 'LKMUDiuS9a1WvyUNt2b3QzjKoSHzK7Uc'
}

export default app