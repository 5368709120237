import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Users from './pages/Users';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Account from './pages/Account';
import Products from './pages/Products';
import Showcase from './pages/Showcase';
import Catalogs from './pages/Catalogs';
import Arrivals from './pages/Arrivals';
import ArrivalCreate from './pages/Arrival-create';
import Orders from './pages/Orders';
import Configuration from './pages/Configuration';
import Suppliers from './pages/Suppliers';
import Brands from './pages/Brands';
import Reviews from './pages/Reviews';
import Sms from './pages/Sms';
// ----------------------------------------------------------------------

export default function Router() {

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <Users /> },
        { path: 'showcase', element: <Showcase /> },
        { path: 'catalog/products', element: <Products /> },
        { path: 'catalogs', element: <Catalogs /> },
        { path: 'suppliers', element: <Suppliers /> },
        { path: 'supplier/brands', element: <Brands /> },
        { path: 'arrivals', element: <Arrivals /> },
        { path: 'arrivals/create', element: <ArrivalCreate /> },
        { path: 'orders', element: <Orders /> },
        { path: 'reviews', element: <Reviews /> },
        { path: 'configuration', element: <Configuration /> },
        { path: 'sms', element: <Sms /> },
        { path: 'account', element: <Account /> },
      ],
    },
  ]);
}
