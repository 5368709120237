import { useState, useEffect } from "react"
import { Container, Grid, Typography, FormControlLabel, Checkbox, FormControl, Radio, RadioGroup, TextField, Button } from "@mui/material";
import MultipleSelectCheckmarks from "src/components/MultiSelect";
import { UserAPI } from "../api/user";
import MenuItem from '@mui/material/MenuItem';
import { SmsAPI } from "../api/sms";

enum Destinator {
    ALL = "ALL",
    WHOLESALER = "WHOLESALER",
    CLIENT = "CLIENT",
    CUSTOM = "CUSTOM"
}

export default function Sms() {
    const [destinator, setDestinator] = useState<string>(Destinator.ALL)
    const [users, setUsers] = useState([])

    const [selected, setSelected] = useState([])
    const [message, setMessage] = useState<string>("")

    useEffect(() => {

        UserAPI.getAll().then(({ data }) => {
            setUsers(data)
        })

    }, [])

    const onSend = () => {
        var send = confirm("Vous confirmez l'envi du messages à ces destinataires ?");
        if (!send) return

        let destinators = []

        switch (destinator) {
            case Destinator.WHOLESALER:
                destinators = users.filter((u) => u.role.code === Destinator.WHOLESALER)
                break;
            case Destinator.CLIENT:
                destinators = users.filter((u) => u.role.code === Destinator.CLIENT)
                break;
            case Destinator.ALL:
                destinators = users
                break;
            case Destinator.CUSTOM:
                destinators = selected
                break;
            default:
                break;
        }

        console.log(destinators)
        SmsAPI.send({ message, destinators }).then(() => alert("SMS envoyé"))
    }

    return (
        <Container >
            <Typography variant="h4">SMS Pro +</Typography>
            <Typography variant="caption">Envoyez des sms à vos clients pour votre campagne marketing</Typography>
            <Grid display="flex" >

                <Grid sx={{ mt: 2 }}>
                    <Typography variant="subtitle1">Séléctionnez les clients que vous voulez notifier</Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={Destinator.ALL}
                        name="radio-buttons-group"
                        onChange={(e) => {
                            setDestinator(e.target.value)
                        }}
                    >
                        <FormControlLabel value={Destinator.ALL} control={<Radio />} label="Tous" />
                        <FormControlLabel value={Destinator.WHOLESALER} control={<Radio />} label="Grossistes uniquement" />
                        <FormControlLabel value={Destinator.CLIENT} control={<Radio />} label="Clients uniquement" />
                        {destinator !== Destinator.CUSTOM && <FormControlLabel value={Destinator.CUSTOM} control={<Radio />} label="Séléctionner manuellement" />}
                    </RadioGroup>

                    {destinator === Destinator.CUSTOM && <MultipleSelectCheckmarks options={users} setValues={setSelected}>
                        {users.map(({ _id, name }) => (
                            <MenuItem
                                key={_id}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </MultipleSelectCheckmarks>}
                </Grid>
                <Grid sx={{ ml: 5, mt: 2 }}>
                    <Typography variant="subtitle1">Ecrivez le message que vous voulez envoyer</Typography>
                    <TextField fullWidth label="Message (150)" type="text" value={message} onChange={(e) => {
                        setMessage(e.target.value)
                    }} inputProps={{ maxLength: 150 }} sx={{ mt: 1 }} rows={4} multiline />
                    <Button variant="outlined" sx={{ mt: 1 }} color="primary" onClick={onSend} fullWidth >Envoyer</Button>
                </Grid>
            </Grid>
        </Container>
    )
} 