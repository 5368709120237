import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../components/Iconify';




export default function ActionsMenu({
    _id,
    newArrival = undefined,
    insertProduct = undefined,
    search = undefined,
    remove = undefined,
    update = undefined,
    highlight = undefined
}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {remove &&
                    <MenuItem onClick={() => { setIsOpen(false), remove(_id) }} sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Supprimer" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
                {update &&
                    <MenuItem onClick={() => { setIsOpen(false), update(_id) }} component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="akar-icons:edit" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Modifier" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
                {insertProduct &&
                    <MenuItem onClick={() => { setIsOpen(false), insertProduct(_id) }} sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="ion:bag-handle" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Gérer produits" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                {newArrival &&
                    <MenuItem onClick={() => { setIsOpen(false), newArrival(_id) }} sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="la:plane-arrival" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Nouvel arrivage" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                {search &&
                    <MenuItem onClick={() => { setIsOpen(false), search(_id) }} sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="dashicons:search" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Parcourir" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                {highlight &&
                    <MenuItem onClick={() => { setIsOpen(false), highlight.setHighlight(_id, !highlight.isHighlighted)}} sx={{ color: 'text.secondary' }}>
                        <ListItemIcon>
                            <Iconify icon="material-symbols:view-carousel-outline-rounded" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary={`${!highlight.isHighlighted ? "Ajouter au" : "Enlever du"} carrousel`} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
            </Menu>
        </>
    );
}
