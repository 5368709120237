import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import OrderItem from './order-item';
import OrderStatusControlled from './order-status';
import { Button, IconButton, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import Iconify from '../Iconify';
import InvoicePrint from 'src/utils/Invoice';
import { OrderAPI } from 'src/api/order';
import { useSnackbar } from 'notistack';
import { fCurrency } from '../../utils/formatNumber.js';
import { useEffect, useMemo, useState } from 'react';
import { ConfigAPI } from '../../api/config'



const psx = {
  m: 2,
  maxWidth: 500,
  flexGrow: 1,
  backgroundColor: (theme) =>
    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
}


/// <devdoc>
///    <para> Order management drawer . </para>
/// </devdoc>


export default function OrderLeftDrawer({ order, open, setOpen, refresh }: any) {
  const { enqueueSnackbar } = useSnackbar();

  /* extract order data */
  const { _id, createdBy, deliveryFee } = order;
  const isWholesaler = createdBy.role.code == 'WHOLESALER';

  const [cart, setCart] = useState(order.cart)
  const [status, setStatus] = useState(order.status)
  const [config, setConfig] = useState("")

  const [documentTitle, setDocumentTitle] = useState("")
  const [documentNum, setDocumentNum] = useState(0)
  const [isTypingDocumentNum, setIsTypingDocumentNum] = useState(false);

  const total = cart.reduce(function (acc, { price, quantity }) { return acc + price * quantity; }, 0);

  /// <summary>
  /// Print invoice event
  /// </summary>

  const onInvoicePrint = (title) => {
    setDocumentTitle(title)

    if (
      title === "BON DE LIVRAISON" &&
      !order.infos?.deliverynoteNum
    ) return setIsTypingDocumentNum(true)

    if (
      title === "FACTURE" &&
      !order.infos?.invoiceNum
    ) return setIsTypingDocumentNum(true)

    InvoicePrint({ ...order, total }, title);
  };

  /// <summary>
  /// Set local state values on left drawer open
  /// </summary>

  useEffect(() => {
    if (!open) return

    ConfigAPI.get().then(({ data }) => {
      setConfig(data)
      setCart(order.cart)
      setStatus(order.status)
    })


  }, [open])

  /// <summary>
  /// update order cart or status only
  /// </summary>

  const onOrderUpdate = () => {
    OrderAPI.update({ _id, cart, status })
      .then((res) => {
        enqueueSnackbar("La commande a été mis a jour.", { variant: 'success' });
        setOpen(false);
        refresh()
      })
      .catch((e) => enqueueSnackbar(e.response.data.message, { variant: 'error' }));

  };


  /// <summary>
  /// update cart
  /// </summary>

  const onCartUpdate = (item, index) => {
    let cart_ = [...cart];
    cart_[index] = item;
    setCart([...cart_]);
  };


  /// <summary>
  /// on close
  /// </summary>

  const onClose = () => {
    if (JSON.stringify(order.cart) == JSON.stringify(cart) && order.status == status) {
      setOpen(false);
    }
    else {
      var proceed = confirm("Êtes-vous sur de vouloir sauvegarder les changements?");
      proceed ? onOrderUpdate() : setOpen(false)
    }
  };


  const handleDocumentPrint = async () => {
    if(!documentNum) return alert("Veuillez entre un numéro valide")

    switch (documentTitle) {
      case "BON DE LIVRAISON":
        order = { ...order, infos: { deliverynoteNum: documentNum } }
        OrderAPI.setDeliverynoteNum({ _id: order._id, number: documentNum }).then(() => {
          InvoicePrint({ ...order, total }, documentTitle)
        })
        break;
      case "FACTURE":
        order = { ...order, infos: { invoiceNum: documentNum } }
        OrderAPI.setInvoiceNum({ _id: order._id, number: documentNum }).then(() => {
          InvoicePrint({ ...order, total }, documentTitle)
        })
        break;
      default:
        return
    }
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
    >
      <Box
        role="presentation"
        sx={{ backgroundColor: "#F5F5F5" }}
      >
        <List sx={{ backgroundColor: "#F5F5F5" }}>
          {cart.map((item, index) => (
            <ListItem key={item._id}>
              <OrderItem item={item} index={index} config={config} onCartUpdate={onCartUpdate} />
            </ListItem>
          ))}
        </List>
        <Paper
          elevation={1}
          sx={[psx, { p: 2 }]}
        >
          <OrderStatusControlled setStatus={setStatus} status={status} />
        </Paper>
        <Paper
          elevation={1}
          sx={psx}
        >
          {!isWholesaler && <>
            <Stack sx={{ pl: 2, pr: 2, pt: 2 }} alignItems="center" direction="row" justifyContent="space-between" >
              <Typography sx={{ mx: 2 }} variant="subtitle1">
                Montant
              </Typography>
              <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                {fCurrency(total)} DZD
              </Typography>
            </Stack>
            <Stack sx={{ pl: 2, pr: 2, pt: 1 }} alignItems="center" direction="row" justifyContent="space-between">
              <Typography sx={{ mx: 2 }} variant="subtitle1">
                Livraison
              </Typography>
              <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                {fCurrency(Number(deliveryFee))} DZD
              </Typography>
            </Stack>
          </>}
          <Stack p={2} alignItems="center" direction="row" justifyContent="space-between" mb={5}>
            <Typography sx={{ mx: 2 }} variant="h4">
              Total
            </Typography>
            <Typography sx={{ fontWeight: "900" }} variant="h3">
              {fCurrency(total + Number(isWholesaler ? "0" : deliveryFee))} DZD
            </Typography>
          </Stack>
        </Paper>
        <Paper
          elevation={1}
          sx={psx}
        >
          {isTypingDocumentNum ?
            <Stack p={2} spacing={1} direction="row" justifyContent="space-around" mb={5}>
              <TextField type='number' placeholder='Numéro du document' onChange={(e) => {
                setDocumentNum(parseInt(e.target.value))
              }} />
              <Button variant='text' color='success' onClick={handleDocumentPrint}>
                OK
              </Button>
              <Button variant='text' color='error' onClick={() => {
                setIsTypingDocumentNum(false)
              }}>
                Annuler
              </Button>
            </Stack>
            :
            <Stack p={2} alignItems="center" direction="row" justifyContent="space-around" mb={5}>
              <Tooltip placement='top' title="Bon de commande">
                <IconButton onClick={() => {
                  onInvoicePrint("BON DE COMMANDE")
                }} edge="end">
                  <Iconify
                    width={40}
                    height={40}
                    icon="line-md:cloud-print-loop"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip placement='top' title="Bon de livraison">
                <IconButton onClick={() => {
                  onInvoicePrint("BON DE LIVRAISON")
                }} edge="end">
                  <Iconify
                    width={40}
                    height={40}
                    icon="mdi:truck-delivery"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip placement='top' title="FACTURE">
                <IconButton onClick={() => {
                  onInvoicePrint("FACTURE")
                }} edge="end">
                  <Iconify
                    width={40}
                    height={40}
                    icon="fa6-solid:file-invoice-dollar"
                  />
                </IconButton>
              </Tooltip>
            </Stack>}
        </Paper>
      </Box>
    </SwipeableDrawer>
  );
}