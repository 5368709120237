import { requests } from './utils';


export const UserAPI = {
	// require admin role
	getAll: () => requests.get(`user/r/all`),
	getWholesalers: () => requests.get(`user/r/wholesalers`),
	getSignupToken: () => requests.get(`user/r/signup/token`),
	stateUpdate: (state) => requests.post(`user/r/state`, state),
	enable: (id) => requests.get(`user/r/enable/${id}`),
	disable: (id) => requests.delete(`user/r/disable/${id}`),
	// require auth 
	get: () => requests.get(`user`),
	update: (info) => requests.post(`user/update`, info),
};
