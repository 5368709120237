import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { ColorPreview } from '../color-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify';
import app from 'src/config';
import { fCurrency } from '../../utils/formatNumber.js';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});


/// <devdoc>
///    <para> Order product item component. </para>
/// </devdoc>


export default function OrderItem({ item, index, onCartUpdate, config }: any) {

  const { color, product, price, quantity } = item;

  const quantityLeft = product.quantityByColor.find((q) => q.color === color._id).quantity

  /// <summary>
  /// delete product from order
  /// </summary>

  const onRemove = () => {
    var proceed = confirm(`Êtes-vous sur de vouloir le supprimer?`);

    if (proceed) {
      // api
    }
  };


  /// <summary>
  /// handle quantity change
  /// </summary>

  const handleQuantityChange = (quantity: string) => {
    if (isNaN(Number(quantity))) return;
    onCartUpdate({ ...item, quantity: Number(quantity) }, index);
  };


  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img
              alt="complex"
              src={product.attachments.length > 0
                ? app.route + product.attachments[0].filename
                : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
              }
            />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="subtitle1" component="div">
                {product.name}
              </Typography>
              <Grid container mb={1}>
                <ColorPreview colors={[`#${color.hex}`]} />
              </Grid>
              <Grid mt={3} item>
                <Typography variant="subtitle1">
                  DZD {fCurrency(price)}
                </Typography>
                <TextField
                  label="Quantité"
                  sx={{ mt: 2 }}
                  style={{ width: 100 }}
                  size="small"
                  id="outlined-number"
                  defaultValue={quantity}
                  type="number"
                  onChange={e => handleQuantityChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' >
                        <Typography color={quantityLeft < config.minimum_quantity ? 'red': 'green'}>{`/ ${quantityLeft}`}</Typography>
                      </InputAdornment>
                    )
                  }}

                />
              </Grid>

              <Stack py={1} direction="row" justifyContent="space-between">

                {/* Barcode */}
                <Stack py={1} direction="row">
                  <Iconify
                    width={20}
                    height={20}
                    icon="la:barcode"
                    color="#919EAB"
                  />
                  <Typography px={1} variant="body2" color="text.secondary">
                    {product.barcode}
                  </Typography>
                </Stack>

                {/* Réference */}
                <Stack py={1} direction="row">
                  <Iconify
                    width={20}
                    height={20}
                    color="#919EAB"
                    icon="fluent:tag-search-24-filled"
                  />
                  <Typography px={1} variant="body2" color="text.secondary">
                    {product.reference}
                  </Typography>
                </Stack>
              </Stack>

              {/* Delete 
              <Button sx={{ ml: -1, color: "#919EAB" }} size="small" startIcon={<DeleteIcon />}>
                Supprimer
              </Button>
              */}
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Paper >
  );
}
