import axios from 'axios';
import store from 'src/app/store';
import app from 'src/config';
import { set, end } from '../app/slices/loader'



// should be in env
const instance = axios.create({
  baseURL: app.baseURL,
  timeout: 600000, // 10 minutes
  headers: { 'x-api-key': app.apiKey },
});




// @request interceptor
instance.interceptors.request.use(async function (req) {
  store.dispatch(set())
  console.log('fetching... ', req.url);

  // has Attachement (convert to form data)
  const urls = ["catalog/create", "supplier/create", 'user/avatar/update', "user/signup"];
  if (urls.includes(req.url)) req.headers['Content-Type'] = 'multipart/form-data';

  const session = JSON.parse(localStorage.getItem('session'));
  if (session) req.headers.Authorization = 'Bearer ' + session.accessToken;
  return req;
});



// @response interceptor
instance.interceptors.response.use(
  function (res) {
    store.dispatch(end())
    return res;
  },
  function (err) {
    store.dispatch(end())
    alert(err.response.data.message);
    // @print error
    console.log('\nStatus : ' + err.response.status + '\n Body : ');
    console.log(err.response.data);
    return Promise.reject(err);
  }
);



/*
 * @response
 * return res.data
 */
const response = (res) => res.data;



// @types
export const requests = {
  get: (url) => instance.get(url).then(response),
  post: (url, body) => instance.post(url, body).then(response),
  put: (url, body) => instance.put(url, body).then(response),
  delete: (url) => instance.delete(url).then(response),
};
