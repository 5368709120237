import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from "clsx";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    CardActions,
    FormControl,
    FormLabel,
    Typography,
    Stack,
    Avatar,
    ButtonBase,
    IconButton,
    styled,
} from '@mui/material';
import Iconify from '../Iconify';
import { BrandAPI } from 'src/api/brand';
import React from 'react';
import { getRandomValues } from 'crypto';
import app from 'src/config';


const StyledBox: any = styled(Box)(({ textTransformStyle, ellipsis }: any) => ({
    textTransform: textTransformStyle || "none",
    whiteSpace: ellipsis ? "nowrap" : "normal",
    overflow: ellipsis ? "hidden" : "",
    textOverflow: ellipsis ? "ellipsis" : "",
}));



export const H4 = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}: any) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h4"
            fontSize="17px"
            fontWeight="600"
            lineHeight="1.5"
            ellipsis={ellipsis}
            textTransformStyle={textTransform}
            className={clsx({
                [className || ""]: true,
            })}
            {...props}
        >
            {children}
        </StyledBox>
    );
};


const ContentWrapper: any = styled(Box)(({ theme, imgUrl }: any) => ({
    color: "white",
    height: 230,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: 1,
    /*
    transition: "all 250ms ease-in-out",
    "&:hover": {
        opacity: 0.9,
    },
    */
    backgroundImage: `url(${imgUrl || "/static/banner.jpg"})`,
}));



/// <devdoc>
///    <para>  Brand interface. </para>
/// </devdoc>


export interface IBrand {
    name: string;
}


/// <devdoc>
///    <para>  Create new brand modal. </para>
/// </devdoc>


export const BrandAdd = ({ create, update, form, supplier }) => {

    const [logo, setLogo] = useState(null)
    const [cover, setCover] = useState(null)


    //const { name, createdAt, brand } = form.brand
    const AddBrandSchema = Yup.object().shape({
        name: Yup.string().max(100).min(3).required('le Nom est requis').typeError('Nom incorrect')
    });


    const formik = useFormik({
        initialValues: form.brand ? form.brand : {
            name: null,
            logo: "",
            cover: null
        } as IBrand,

        validationSchema: AddBrandSchema,
        onSubmit: (values, actions) => {
            onSubmit(values);
        },
    });



    /// <summary>
    /// on component mount
    /// </summary>


    React.useEffect(() => {

        // update formik initial values on edit
        if (form.brand && formik.initialValues != form.brand) {
            formik.setValues({ ...form.brand });
        }

        return () => { }
    }, [form])



    /// <summary>
    /// Handle Logo file upload
    /// </summary>


    const handleLogoUpload = (e) => {
        setLogo(e.target.files[0])
    }


    /// <summary>
    /// Handle cover photo file upload
    /// </summary>


    const handleCoverUpload = (e) => {
        setCover(e.target.files[0])
    }

    /// <summary>
    /// is editing form
    /// </summary>


    const isEditing = (): boolean => {
        return form.brand != null
    }



    /// <summary>
    /// Submit brand
    /// </summary>


    const onSubmit = (values) => {
        const brand = new FormData();

        // set name
        brand.append('name', values.name);

        // set supplier id
        brand.append('supplier', supplier._id);

        // set file
        if (logo) {
            brand.append('attachments', new File([logo], "isAvatar_" + logo.name)); // index 0
        }
        
        if (cover) brand.append('attachments', cover); // index 1

        if (logo != null && cover == null) brand.append('isLogoOnly', "true");

        if (isEditing()) {
            brand.append('_id', form.brand._id);
            update(brand);
            return;
        }

        create(brand);
    }



    const { errors, touched, values, setFieldValue, handleChange, handleSubmit, getFieldProps }: any = formik;

    return (
        <form
            autoComplete="off"
            noValidate
        //{...props}
        >
            <Card>
                <CardHeader
                    subheader="Informations"
                    title={`${isEditing() ? "Modifier" : "Ajouter"} Marque`}
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <ContentWrapper
                                sx={{ cursor: "pointer", borderRadius: 2, mb: 2, p: 2 }}
                                imgUrl={cover
                                    ? URL.createObjectURL(cover)
                                    : (values.cover ? app.route + values.cover.filename
                                        : "/static/banner.jpg")}
                            >
                                <input
                                    onChange={handleCoverUpload}
                                    type="file"
                                    accept="image/*"
                                    id="raised-button-file"
                                    //multiple
                                    name="brand[file]"
                                />
                                <Stack
                                    direction="row"
                                    display="flex"
                                    justifyContent="center"
                                    height="100%"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button
                                        sx={{ borderRadius: 50 }}
                                        variant="text"
                                        component="label"
                                    >
                                        <input
                                            onChange={handleLogoUpload}
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            id="raised-button-file"
                                            //multiple
                                            name="brand[file]"
                                        />
                                        <Avatar
                                            src={logo ? URL.createObjectURL(logo) : (app.route + values.logo.filename || "")}
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                border: "3px solid",
                                                borderColor: "grey.100",
                                            }}
                                        />
                                    </Button>
                                    <Box
                                        borderRadius="4px"
                                        display="inline-block"
                                        bgcolor="grey.300"
                                    >
                                        <H4
                                            p={1}
                                            color="black">
                                            {
                                                <FormControl fullWidth variant="outlined">
                                                    <TextField
                                                        id="supplier"
                                                        variant="standard"
                                                        value={values.name}
                                                        onChange={handleChange('name')}
                                                        size="small"
                                                        sx={{ width: 'calc(100% - 5px)' }}
                                                        fullWidth
                                                        InputProps={{
                                                            style: {
                                                                fontSize: 17, fontWeight: "600", lineHeight: "1.5",
                                                            },
                                                            disableUnderline: true,
                                                        }}
                                                        placeholder="Nom de la marque"
                                                        aria-describedby="outlined-weight-helper-text"
                                                        {...getFieldProps('name')}
                                                        error={Boolean(touched.name && errors.name)}
                                                        helperText={touched.name && errors.name}
                                                    />
                                                </FormControl>
                                            }
                                        </H4>
                                    </Box>
                                </Stack>
                            </ContentWrapper>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <CardHeader
                                    title="note"
                                    subheader="Une marque doit avoir un logo et une photo de couverture."
                                />
                                <Button
                                    sx={{ justifySelf: "flex-end" }}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {isEditing() ? "Modifier" : "Créer"}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form >
    );
};
