import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';


export function AccountProfile({ user }: any) {

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={'/static/images/avatars/avatar_6.png'}
            sx={{
              height: 64,
              mb: 2,
              width: 64
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {user.name}
          </Typography>
          {/* <Typography
            color="textSecondary"
            variant="body2"
          >
            {`${user.name} | Algérie`}
          </Typography> */}
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {new Date(user.createdAt).toDateString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
};
