import * as React from 'react';
import Stack from '@mui/material/Stack';
import { OrderStatus } from 'src/pages/Orders';
import Label from '../Label';
import { sentenceCase } from 'change-case';
import { Box, Typography } from '@mui/material';


/// <devdoc>
///    <para> Order status controller. </para>
/// </devdoc>



export default function OrderStatusControlled({ setStatus, status }) {

    return (
        <Box my={2}>
            <Typography variant="h5" gutterBottom>
                Status
            </Typography>
            <Stack direction="row" spacing={2}>
                {["PENDING", "CONFIRMED", "CANCELED", "DELIVERED"]
                    .map((s) => (
                        <div onClick={() => setStatus(s)}>
                            <Label selected={s == status} variant="ghost" color={OrderStatus[s]}>
                                {sentenceCase(s)}
                            </Label>
                        </div>
                    ))}
            </Stack>
        </Box>
    );
}
