import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { fCurrency } from '../../utils/formatNumber.js';
import { PaymentAPI } from 'src/api/payment';



const psx = {
    m: 2,
    maxWidth: 300,
    minWidth: 300,
    flexGrow: 1,
    backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
}


enum PaymentStatus {
    Pending = "PENDING",
    Completed = "COMPLETED",
    Canceled = "CANCELED",
    Refunded = "REFUNDED"
}

/// <devdoc>
///    <para> Payment status view drawer . </para>
/// </devdoc>


export default function PaymentRightDrawer({ payment, open, setOpen, order }) {
    const { enqueueSnackbar } = useSnackbar();

    /* extract payment anda order data */
    const { status, result, updatedAt, transaction_id } = payment;
    const { cart, number, createdBy, deliveryFee, _id } = order

    const total = cart.reduce(function (acc, { price, quantity }) { return acc + price * quantity; }, 0);
    const isWholesaler = createdBy.role.code == 'WHOLESALER';

    const refund = () => {
        const r = confirm("Voulez-vous vraiment rembourser ce client ?")
        if (!r) return

        PaymentAPI.refund(_id).then((res) => {
            enqueueSnackbar("Le client a été remboursé", { variant: 'success' })
            setOpen(false)
        }).catch((error) => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' })
        })
    }

    /// <summary>
    /// on close
    /// </summary>

    const onClose = () => {
        setOpen(false);
    };

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            onOpen={() => setOpen(true)}
        >
            <Box
                role="presentation"
                sx={{ backgroundColor: "#F5F5F5" }}
            >
                <Paper
                    elevation={1}
                    sx={psx}
                >
                    <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Statut
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {status == PaymentStatus.Completed ? <Chip label="Paiement accepté" variant="filled" color='success' />
                                : status == PaymentStatus.Canceled ? <Chip label="Le paiement a échoué" variant="filled" color='error' />
                                    : status == PaymentStatus.Refunded ? <Chip label="Remboursé" variant="filled" color='secondary' />
                                        : <Chip label="Paiement en cours" variant="filled" color='warning' />}
                        </Typography>
                    </Stack>
                    <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Numéro de commande
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {number}
                        </Typography>
                    </Stack>
                    <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Numéro de transaction
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {transaction_id && transaction_id}
                        </Typography>
                    </Stack>
                    <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Total
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {fCurrency(total + Number(isWholesaler ? "0" : deliveryFee))} DZD
                        </Typography>
                    </Stack>
                    {status != PaymentStatus.Pending && <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            {status == PaymentStatus.Canceled ? 'Tentative de paiement le' : 'Date de paiement'}
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {`${new Date(updatedAt).toLocaleDateString()} à ${new Date(updatedAt).toLocaleTimeString()}`}
                        </Typography>
                    </Stack>}
                    <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Etat
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }} variant="subtitle1">
                            {result && result.params 
                            ? result.params.respCode_desc : result && !result.params 
                            ? result.actionCodeDescription : "Inconnu"}
                        </Typography>
                    </Stack>
                    {status == PaymentStatus.Completed && <Stack p={2} mb={2}>
                        <Typography variant="h4">
                            Action
                        </Typography>
                        <Button color='warning' onClick={refund} variant="contained">Rembourser</Button>
                    </Stack>}
                </Paper>
            </Box>
        </SwipeableDrawer>
    );
}