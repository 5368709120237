import { useEffect, useState } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { useSnackbar } from 'notistack';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import { ProductAPI } from 'src/api/product';
// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);
  const [products, setProducts] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  /// <summary>
  /// Set products data, gets updated on products state change
  /// <Returns> returns cleanup function</Returns>
  /// </summary>


  useEffect(() => {
    if (!products) get();
    return () => { }
  }, [])



  /// <summary>
  /// Load products api.
  /// <Exception>throw UnauthorizedAccessException if access is denied</Exception>
  /// </summary>


  const get = () => {
    ProductAPI.get()
      .then(res => setProducts(res.data))
      .catch((e) => enqueueSnackbar('Chargement échoué.', { variant: 'error' }));
  }

  /// <summary>
  /// open filter.
  /// </summary>

  const handleOpenFilter = () => {
    //setOpenFilter(true);
  };

  /// <summary>
  /// close filter.
  /// </summary>

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <Page title="Produits">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Produits
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>

            <ProductFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        {products && <ProductList products={products} />}
        {/* <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
